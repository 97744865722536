<template>
    <div class="edit-idea">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">Редактирование идеи</h3>
                    <button type="button" class="btn-block-option close-modal" @click="$modal.hide('idea-update')">×
                    </button>
                </div>
                <div class="edit-idea__tabs" v-if="form.status === 3 || form.status === 4">
                    <div class="edit-idea__tabs-item"
                         v-for="(tab, index) in tabsList" :key="index"
                         :class="{'active': tab.code === tabActive}"
                         :item="tab"
                         @click="tabActive = tab.code">
                        {{ tab.name }}
                    </div>
                </div>
                <ValidationObserver tag="form" @submit.prevent="onSubmit" ref="observer">
                    <div class="block-content content" v-show="tabActive === 'node'">
                        <div class="edit-idea__form">
                            <ValidationProvider vid="title" rules="required|max_length:255" v-slot="provider">
                                <div class="edit-idea__form-block form-group"
                                     v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                    <label class="sr-only" for="idea-title">Название идеи</label>
                                    <input class="edit-idea__form-input form-control" id="idea-title" type="text"
                                           v-model="form.title" placeholder="Озаглавьте идею">
                                    <div id="idea-title-error" class="invalid-feedback animated fadeInDown"
                                         v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <div class="edit-idea__form-block">
                                <ValidationProvider vid="text" rules="required" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label class="sr-only" for="idea-description">Описание
                                            идеи</label>
                                        <div class="edit-idea__form-textarea-wrapper">
                                            <textarea class="edit-idea__form-textarea form-control" id="idea-description"
                                                      v-model="form.text"
                                                      placeholder="Введите описание идеи"></textarea>
                                            <div class="edit-idea__textarea-footer">
                                                <ul v-if="imageList.length" class="edit-idea__img-list">
                                                    <li class="edit-idea__img-item" v-for="(img, index) in imageList" :key="`image_${index}`"
                                                        :style="{backgroundImage: `url(${img.src ? img.src : img.file})`}">
                                                    <span class="edit-idea__img-delete" @click="onImgDelete(img)"
                                                          title="Удалить"/>
                                                    </li>
                                                </ul>
                                                <div class="edit-idea__attach-wrapper">
                                                    <div class="edit-idea__attach-block" v-if="false">
                                                        <input class="sr-only edit-idea__input-file" id="idea-file"
                                                               type="file">
                                                        <label class="edit-idea__input-label" for="idea-file"
                                                               aria-label="Прикрепите файл к описанию идеи">
                                                            <svg width="22" height="25" viewBox="0 0 22 25" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M5.68329 21.4678C2.54916 19.6583 1.47176 15.6374 3.28125 12.5032L7.875 4.54663C8.11693 4.1276 8.652 3.98493 9.0702 4.22638C9.48854 4.46791 9.63252 5.00263 9.39059 5.42166L4.79684 13.3783C3.47032 15.6759 4.26003 18.6253 6.55832 19.9522C8.85661 21.2791 11.8057 20.4883 13.1322 18.1907L17.9448 9.85517C18.7891 8.39273 18.2863 6.51621 16.8239 5.67187C15.3613 4.82744 13.4848 5.33025 12.6404 6.7927L8.26536 14.3705C7.90359 14.9971 8.11902 15.8016 8.74576 16.1634C9.37251 16.5253 10.1769 16.3096 10.5387 15.683L14.6949 8.48413C14.9369 8.0651 15.4719 7.92243 15.8901 8.16388C16.3085 8.40541 16.4525 8.94013 16.2105 9.35916L12.0543 16.558C11.2099 18.0205 9.33327 18.5232 7.87082 17.6789C6.40837 16.8345 5.90542 14.9579 6.74977 13.4955L11.1248 5.91767C12.4513 3.62021 15.4005 2.82928 17.6989 4.15628C19.9972 5.4832 20.7868 8.43274 19.4604 10.7302L14.6478 19.0657C12.8383 22.1999 8.81743 23.2773 5.68329 21.4678Z"/>
                                                            </svg>
                                                        </label>

                                                    </div>
                                                    <div class="edit-idea__attach-block">
                                                        <input class="sr-only edit-idea__input-file" id="idea-image"
                                                               type="file"
                                                               @change="addImgHandler" accept="image/*">
                                                        <label class="edit-idea__input-label" for="idea-image"
                                                               aria-label="Прикрепите изображение к описанию идеи">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="18" height="18" rx="2"/>
                                                                <circle cx="4.5" cy="4.5" r="1.5" fill="white"/>
                                                                <path
                                                                    d="M5.5 10L2.29289 13.2071C2.10536 13.3946 2 13.649 2 13.9142V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V4.5L9 12L5.5 10Z"
                                                                    fill="white"/>
                                                            </svg>
                                                        </label>

                                                    </div>
                                                    <div class="edit-idea__attach-block" v-if="false">
                                                        <input class="sr-only edit-idea__input-file" id="idea-video"
                                                               type="file"
                                                               accept="video/mp4, video/x-m4v, video/*">
                                                        <label class="edit-idea__input-label" for="idea-video"
                                                               aria-label="Прикрепите видео к описанию идеи">
                                                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M19.0155 0.508545H4.98449C2.23163 0.508545 0 2.74018 0 5.49304V12.5071C0 15.2599 2.23163 17.4916 4.98449 17.4916H19.0155C21.7684 17.4916 24 15.2599 24 12.5071V5.49304C24 2.74018 21.7684 0.508545 19.0155 0.508545ZM15.6445 9.34131L9.08177 12.4713C8.9069 12.5547 8.7049 12.4273 8.7049 12.2335V5.77781C8.7049 5.58133 8.91221 5.45399 9.08744 5.54281L15.6502 8.86849C15.8453 8.96735 15.8419 9.24718 15.6445 9.34131Z"/>
                                                            </svg>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="idea-description-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>

                                <vue-progress-bar></vue-progress-bar>

                            </div>
                            <div class="edit-idea__form-footer">
                                <ValidationProvider vid="responsible" :rules="form.status === 2 || form.status === 3 || form.status === 4 ? 'required' : null" v-slot="provider" class="edit-idea__select-label">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label>
                                            Ответственный
                                            <v-select2
                                                v-model="form.responsible"
                                                class="edit-idea__select-tag"
                                                :options="responsibleList"
                                                placeholder="Не назначено"
                                                label="full_name"
                                                :reduce="full_name => full_name.id"
                                            >
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>
                                        </label>
                                        <div id="idea-responsible-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <span class="edit-idea__select-label">
                                    <div class="form-group">
                                        <label>
                                            Тег
                                            <v-select2
                                                v-model="form.tag"
                                                class="edit-idea__select-tag"
                                                :options="tagList"
                                                placeholder="Выберите тег"
                                                label="name"
                                                :reduce="name => name.id"
                                            >
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>
                                        </label>
                                    </div>
                                </span>

                                <span class="edit-idea__select-label">
                                    <div class="form-group">
                                        <label>
                                            Статус
                                            <v-select2
                                                v-model="form.status"
                                                class="edit-idea__select-tag"
                                                :options="statusList"
                                                placeholder="Выберите статус"
                                                :clearable="false"
                                                label="name"
                                                :reduce="name => name.id"
                                            >
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>
                                        </label>
                                    </div>
                                </span>
                                <ValidationProvider vid="deadline" rules="required" v-slot="provider" v-if="form.status === 2" class="edit-idea__select-label">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label>
                                            Дата завершения
                                        </label>
                                        <VueDatePicker
                                            class="form-control"
                                            color="#8e8e8e"
                                            :validate="true"
                                            :noHeader="true"
                                            :noCalendarIcon="true"
                                            :locale="{lang: 'ru'}"
                                            :format="'DD MMM YYYY'"
                                            placeholder=""
                                            :clearable="true"
                                            :minDate="new Date()"
                                            v-model="form.deadline" />
                                        <div id="idea-deadline-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <button class="v-btn edit-idea__form-submit-btn" type="submit">Сохранить</button>
                        </div>
                    </div>
                    <div class="block-content content" v-show="tabActive === 'solution'">
                        <ValidationProvider vid="reason" rules="required" v-slot="provider" v-if="form.status === 4">
                            <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                <label class="sr-only" for="idea-description">Введите причину отклонения</label>
                                <div class="edit-idea__form-textarea-wrapper">
                                    <textarea class="edit-idea__form-textarea form-control" id="idea-reason"
                                              v-model="form.reason"
                                              placeholder="Причина отклонения"></textarea>
                                </div>
                                <div id="idea-reason-error" class="invalid-feedback animated fadeInDown"
                                     v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="result" rules="required" v-slot="provider" v-if="form.status === 3">
                            <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                <label class="sr-only" for="idea-description">Результат</label>
                                <div class="edit-idea__form-textarea-wrapper">
                                    <textarea class="edit-idea__form-textarea form-control" id="idea-result"
                                              v-model="form.result"
                                              placeholder="Введите результат"></textarea>
                                </div>
                                <div id="idea-result-error" class="invalid-feedback animated fadeInDown"
                                     v-if="provider.errors.length > 0">{{ provider.errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                        <button class="v-btn edit-idea__form-submit-btn" type="submit">Сохранить</button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import session from '@/api/session';

export default {
    name: 'IdeaUpdate',
    data() {
        return {
            imageList: [],
            tagList: [],
            statusList: [],
            responsibleList: [],
            form: {
                title: '',
                text: '',
                tag: '',
                status: '',
                result: '',
                reason: '',
                deadline: '',
                responsible: '',
                images: [],
                deleteImages: [],
                is_private: false
            },
            tabsList: [
                {
                    name: 'Описание',
                    code: 'node'
                },
                {
                    name: 'Решение',
                    code: 'solution'
                }
            ],
            tabActive: 'node'
        };
    },
    props: {
        ideaId: {
            required: true
        }
    },
    async created() {
        await Promise.all([
            session.get('/api/v1/idea/default_form/'),
            session.get(`/api/v1/idea/${this.ideaId}/for_update/`)
        ])
            .then(async ([res1, res2]) => {
                const data1 = res1.data;
                const data2 = res2.data;

                this.tagList = data1.tags;
                this.statusList = data1.status;
                this.responsibleList = data1.responsible;

                this.form = data2;
                this.imageList = this.form.images;
            })
            .catch((error) => {
                console.error(error);
            })
    },
    methods: {
        async onSubmit() {
            const isValidModal = await this.$refs.observer.validate();
            console.log('isValidModal', isValidModal);
            if (isValidModal) {
                try {
                    this.$Progress.start();

                    this.form.images = this.imageList.map(img => img.file);
                    const
                        config = {};
                        let data = this.form;

                    if (this.imageList.length > 0) {
                        if (data.tag === null) {
                            data.tag = '';
                        }

                        if (data.deadline === null) {
                            data.deadline = '';
                        }

                        if (data.changed_at === null) {
                            data.changed_at = '';
                        }

                        if (data.responsible === null) {
                            data.responsible = '';
                        }

                        config.headers = {
                            'Content-Type': 'multipart/form-data'
                        };
                        // Добавляем данные из объекта в formData для отправки на сервер
                        const formData = new FormData();
                        for (const [key, value] of Object.entries(data)) {
                            // Если значение - массив, то переводим его значения в формат images[0]: file, images[1]: file...
                            if (typeof (value) === 'object' && key === 'votes') {
                                for (const item of value) {
                                    formData.append(`${key}[]`, JSON.stringify(item));
                                }
                            } else if (typeof (value) === 'object') {
                                for (const item of value) {
                                    formData.append(`${key}[]`, item);
                                }
                            } else {
                                formData.append(key, value);
                            }
                        }
                        data = formData;
                    }
                    const request = session.put(`/api/v1/idea/${this.ideaId}/`, data, config);
                    const response = await request;

                    this.$modal.hide('idea-update', { idea: response.data });
                    this.$swal({
                        title: 'Идея обновлена!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    this.$Progress.finish();
                } catch (err) {
                    console.log(err);
                    if (err.response) {
                        this.$refs.observer.setErrors(err.response.data);
                    } else {
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400
                        });
                    }
                    this.$Progress.finish();
                }
            }
        },
        addImgHandler: function(e) {
            const file = e.target.files[0];
            const ALLOWED_TYPES = /\.(jpe?g|png)$/i;
            const { imageList } = this;

            /* Очистка значения у поля для того, чтобы можно было загружать один и тот же файл подряд */
            e.target.value = null;

            if (!ALLOWED_TYPES.test(file.name)) {
                this.toastError('Допустимые форматы: png, jpg, jpeg');
                return false;
            } else if (file.size > 5000000) {
                this.toastError('Максимальный размер изображения: 5мб');
                return false;
            } else if (this.imageList.length > 10) {
                this.toastError('Максимум изображений: 10');
                return false;
            } else {
                this.imageList.push({
                    id: imageList.length ? [...imageList].pop().id + 1 : 1,
                    file,
                    src: URL.createObjectURL(file)
                });
            }
        },
        onImgDelete: function(item) {
            if (item.file) {
                this.form.deleteImages.push(item.id);
            }
            this.imageList = this.imageList.filter(img => img !== item);
        },
        toastError(text, timer = 3000) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                title: text,
                icon: 'error',
                showConfirmButton: false,
                timer: timer,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer);
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                }
            });
        }
    }
};
</script>

<style lang="scss">
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.modal-dialog {
    max-width: 840px;
    margin: 0;

    @include below($tablet) {
        max-width: none;
    }
}

.modal-content {
    padding: 30px;

    @include below($mobile) {
        padding: 15px;
    }
}

.modal-header {
    padding: 0;
    margin-bottom: 35px;

    @include below($mobile) {
        margin-bottom: 15px;
    }
}

.block-content {
    padding: 0;
}

.edit-idea {
    &__tabs {
        width: 100%;
        border-bottom: 1px solid #C5CDD9;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 40px;
    }

    &__tabs-item {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 23px;
        flex-basis: 67px;
        min-height: 39px;
        margin-right: 32px;
        margin-bottom: -1px;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }

    &__form-input {
        width: 100%;
        height: 46px;
        padding: 0 13px;
        border: 1px solid $border-gray;
        border-radius: 6px;

        font-size: rem(16);
        line-height: 2.875;

        transition: border-color 0.3s;

        &::placeholder {
            color: $text-gray;
        }

        &:focus {
            outline: none;
        }
    }

    &__form-textarea-wrapper {
        position: relative;

        min-height: 335px;
        border: 1px solid $border-gray;
        border-radius: 6px;
        transition: border-color 0.3s;

        .is-invalid & {
            border-color: $error-text;
        }
    }

    &__form-textarea {
        width: 100%;
        min-height: 224px;
        padding: 14px;
        border: none;

        resize: none;
        overflow: auto;

        @include below($mobile) {
            min-height: 200px;
        }

        &::placeholder {
            color: $text-gray;
        }

        &:focus {
            outline: none;
        }
    }

    &__textarea-footer {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;
        z-index: 2;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__img-list {
        display: flex;
        overflow-x: auto;
        margin-right: 20px;
    }

    &__img-item {
        height: 80px;
        width: 80px;
        min-height: 80px;
        min-width: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: 1;

        &:nth-child(n+2) {
            margin-left: 15px;
        }
    }

    &__img-delete {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;

        width: 16px;
        height: 16px;

        background-color: rgba(0, 0, 0, .7);
        background-image: $times-icon;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    &__attach-wrapper {
        display: flex;
        align-items: center;

        margin-left: auto;
    }

    &__attach-block {
        margin-right: 19px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__input-label {
        cursor: pointer;

        svg {
            fill: #DBDEE9;
            transition: all 0.3s;
        }
    }

    &__input-file:hover + .edit-idea__input-label,
    &__input-file:focus + .edit-idea__input-label {
        svg {
            fill: $gold;
        }
    }

    &__form-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin-bottom: 30px;
    }

    &__form-privacy-toggle {

        @include below($mobile) {
            margin-bottom: 15px;
        }
    }

    &__select-label {
        display: flex;
        flex-wrap: wrap;

        width: 45%;
        margin-bottom: 0;

        font-size: rem(14);
        line-height: 1.71;
        font-weight: 400;

        color: $text-gray;

        @include below($mobile) {
            width: 100%;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__select-tag {
        width: 100%;
        margin-top: 5px;

        @include below($mobile) {
            flex-grow: 1;
        }
    }
}

.active {
    border-bottom: 3px solid #FBC04F;
}

.form-group {
    width: 100%;
    label {
        width: 100%;
        font-weight: 400 !important;
    }
}

</style>
